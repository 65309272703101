// extracted by mini-css-extract-plugin
export var footer = "Footer-module--footer--uIKjD";
export var storeLinks = "Footer-module--storeLinks--2F226";
export var buttons = "Footer-module--buttons--1eZc1";
export var gap = "Footer-module--gap--c4sXP";
export var button = "Footer-module--button--3lpzW";
export var rights = "Footer-module--rights--2sf7p";
export var disclamer = "Footer-module--disclamer--2PPTz";
export var games = "Footer-module--games--2Dyk4";
export var game = "Footer-module--game--2Y9Pn";
export var socialMediaContainer = "Footer-module--socialMediaContainer--3yeSM";
export var center = "Footer-module--center--1lQWq";