import React from 'react'

const support_format_webp = () => {
  var elem = document.createElement('canvas')

  if (!!(elem.getContext && elem.getContext('2d'))) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0
  } else {
    return false
  }
}

function isSafari() {
  return true // Until the detection works properly

  if (typeof navigator !== 'undefined') {
    return navigator.userAgent.indexOf('Safari') >= 0
    // For Mac Chrome, the userAgent contains both "Chrome" and "Safari", so
    // that one produces a false positive...
  }
  // The code is probably running in Node or something
  return false
}

const Img = (props) => {
  const { src, alt, fallback, className } = props
  // console.log('Img:', isSafari(), src.indexOf('.webp') >= 0)
  // console.log('Img:', false)
  if (isSafari() && fallback) {
    var safarifiedSrc = src.replace('.webp', fallback)
    return <img src={safarifiedSrc} alt={alt} className={className} />
  } else {
    return <img src={src} alt={alt} className={className} />
  }
}

export default Img
