import React from 'react'
const FacebookFilled = (props) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 4C5.79086 4 4 5.79086 4 8V24C4 26.2091 5.79086 28 8 28H20.1974H24C26.2091 28 28 26.2091 28 24V8C28 5.79086 26.2091 4 24 4H8ZM20.1974 28V18.4982H23.7148L23.875 15.0773H20.1974V12.5792C20.1974 11.6359 20.3933 11.0677 21.6149 11.0677C22.7436 11.0677 23.778 11.0775 23.778 11.0775L23.8577 7.88292C23.8577 7.88292 22.8435 7.75 21.4719 7.75C18.0844 7.75 16.6139 9.85832 16.6139 12.1545V15.0772H14.125V18.4982H16.6139V28L20.1974 28Z'
      fill={props.fill || 'white'}
    />
  </svg>
)

export default FacebookFilled
