import React from 'react'
import * as styles from './Header.module.css'
import Img from '../Img'

const Header = ({ CDN_ROOT }) => {
  return (
    <div className={styles.header}>
      <div className={styles.headerCenter}>
        <a href='/'>
          <div className={styles.headerLogo}>
            <Img
              src={CDN_ROOT + 'shared/logos/unkilled_big.png'}
              alt='unkilled logo'
            />
          </div>
        </a>
        <div className={styles.headerNavbar}>
          {/*<div className={styles.headerNavbarItem}>
            <a href='https://www.madfingergames.com/forum'>
              <div>{'FORUM'}</div>
            </a>
          </div>*/}
          <div className={styles.headerNavbarItem}>
            <a href='https://support.decagames.com/hc/en-us/requests/new?ticket_form_id=10113802195741' target={'_blank'}>
              <div>{'Contact'}</div>
            </a>
          </div>
          <div className={styles.headerNavbarItem}>
            <a href='https://support.decagames.com/hc/en-us/articles/10111104743965-Privacy-Policy' target={'_blank'}>
              <div>{'Privacy Policy'}</div>
            </a>
          </div>
          <div className={[styles.headerNavbarItem, styles.headerLongItem].join(' ')}>
            <a href='https://support.decagames.com/hc/en-us/articles/10111110259485-Terms-of-Service' target={'_blank'}>
              <div>{'Terms of service'}</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
