import React from 'react'
import Img from '../Img'
import {
  FacebookFilled,
  InstagramFilled,
  LinkedInBig,
  MediumFilled,
  TwitterFilled,
  YoutubeFilled,
} from '../icons'
import * as styles from './Footer.module.css'

const games = [
  {
    img: 'https://mfg-home.b-cdn.net/unkilled/newWeb/logos/unkilled.webp',
    alt: 'unkilled',
    link: '/',
    target: '_self',
  },
  {
    img: 'https://mfg-home.b-cdn.net/unkilled/newWeb/logos/dead_trigger_2.webp',
    alt: 'deadtrigger2',
    link: 'https://www.deadtrigger2.com/',
    target: '_blank',
  },
  {
    img: 'https://mfg-home.b-cdn.net/unkilled/newWeb/logos/shadow_gun_legends.webp',
    alt: 'legends',
    link: 'https://www.shadowgunlegends.com/',
    target: '_blank',
  },/*
  {
    img: 'https://mfg-home.b-cdn.net/unkilled/newWeb/logos/samurai_II_vengeance.webp',
    alt: 'samurai_II',
    link: '/',
  },
  {
    img: 'https://mfg-home.b-cdn.net/unkilled/newWeb/logos/monzo.webp',
    alt: 'monzo',
    link: 'https://www.monzoapp.com/',
  },*/
]

const SocialMediaStrip = () => {
  return (
    <div className={styles.center}>
      <div className={styles.socialMediaContainer}>
        <a
          href='https://www.instagram.com/unkilledgame/'
          rel='noopener noreferrer'
          alt='instagram'
          aria-label='instagram'
          target='_blank'>
          <InstagramFilled />
        </a>
        {/* <a
          href='https://twitter.com/madfingergames'
          rel='noopener noreferrer'
          alt='twitter'
          aria-label='twitter'
          target='_blank'>
          <TwitterFilled />
        </a> */}
        <a
          href='https://www.facebook.com/UNKILLEDgame'
          rel='noopener noreferrer'
          alt='facebook'
          aria-label='facebook'
          target='_blank'>
          <FacebookFilled />
        </a>
        { /* <a
          href='https://www.linkedin.com/company/madfinger-games-as/'
          rel='noopener noreferrer'
          alt='linkedin'
          aria-label='linkedin'
          target='_blank'>
          <LinkedInBig />
        </a>
        <a
          href='https://medium.com/madfingergames'
          rel='noopener noreferrer'
          alt='medium'
          aria-label='medium'
          target='_blank'>
          <MediumFilled />
        </a>
        <a
          href='https://www.youtube.com/user/MadFingerGames'
          rel='noopener noreferrer'
          alt='youtube'
          aria-label='youtube'
          target='_blank'>
          <YoutubeFilled />
        </a> */ }
      </div>
    </div>
  )
}

const Footer = ({ CDN_ROOT }) => (
  <div className={styles.footer}>
    <div className={styles.disclamer}>
      <h5>{'ENJOY DECAGAMES GAMES'}</h5>
    </div>
    <div className={styles.storeLinks}>
      <a
        name='uk-gplay'
        href={`https://play.google.com/store/apps/details?id=com.madfingergames.unkilled`}>
        <Img
            src={CDN_ROOT + 'shared/stores/gplay.png'}
            alt='unkilled gplay store'
        />
      </a>
      <a
        name='uk-appstore'
        href='https://apps.apple.com/nz/app/unkilled-zombie-fps-shooter/id969488951'>
        <Img
            src={CDN_ROOT + 'shared/stores/appstore.png'}
            alt='unkilled apple store'
        />
      </a>
    </div>
    <SocialMediaStrip />
    <div>
      <div className={styles.games}>
        {games.map((game, index) => {
          return (
            <div className={styles.game} key={game.alt + index}>
              <a href={game.link} target={game.target}>
                <Img src={game.img} alt={game.alt} fallback={'.png'} />
              </a>
            </div>
          )
        })}
      </div>
    </div>

    <div className={styles.rights}>
      {'All rights reserved.'}
      <br />
      {'“DECAGAMES”®️'}
    </div>
  </div>
)

export default Footer
