import React, { useState } from 'react'
import * as styles from './CookiesBanner.module.css'

const CookiesBanner = () => {
  const [displayCookies, setDisplayCookies] = useState(false)

  if (displayCookies) {
    return null
  } else {
    return (
      <div className={styles.wrap}>
        <div className={styles.text}>
          <span>
            {
              'We are using cookies. By visiting this site, you agree to the use of cookies. '
            }
            <a href='https://support.decagames.com/hc/en-us/articles/10111104743965-Privacy-Policy' target={'_blank'}>Read more</a>
          </span>
        </div>

        <div className={styles.button} onClick={() => setDisplayCookies(true)}>
          <span>{'OK'}</span>
        </div>
      </div>
    )
  }
}

export default CookiesBanner
