import React from 'react'
import { Helmet } from 'react-helmet'
import Header from './header/Header'
import Footer from './footer/Footer'
import CookiesBanner from './banner/CookiesBanner'
import * as styles from './global.module.css'

// import AnalyticsInit from '../components/analytics'

// AnalyticsInit('uk')
const Layout = ({ CDN_ROOT, children }) => (
  <>
    <Helmet>
      <title>Unkilled | FPS for mobile by DECA Games</title>
      <html lang='en' />
      <meta
        name='description'
        content='The multiple-times awarded best-looking mobile shooter you have ever seen. Online multiplayer, extra long storyline, special ops, many unique weapons.'
      />
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />
      <meta
        name='og:image'
        content={`${CDN_ROOT}unkilled/images/misc/preview.jpg`}
      />
      <meta name='apple-itunes-app' content='app-id=969488951' />
      <link rel='icon' href={`${CDN_ROOT}unkilled/images/icons/favicon.ico`} />
      <link rel='manifest' href={'static/manifest/unkilled.json'} />
    </Helmet>
    <div className={styles.body}>
      <Header CDN_ROOT={CDN_ROOT} />
      <div>{children}</div>
      <Footer CDN_ROOT={CDN_ROOT} />
      <CookiesBanner />
    </div>
  </>
)

export default Layout
