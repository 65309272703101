import React from 'react'
const InstagramFilled = (props) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 4C5.79086 4 4 5.79086 4 8V24C4 26.2091 5.79086 28 8 28H24C26.2091 28 28 26.2091 28 24V8C28 5.79086 26.2091 4 24 4H8ZM8.875 16.1869C8.875 20.0199 11.9764 23.125 15.8049 23.125C17.6428 23.125 19.4054 22.394 20.705 21.0929C22.0046 19.7917 22.7348 18.027 22.7348 16.1869C22.7348 14.3468 22.0046 12.582 20.705 11.2809C19.4054 9.97971 17.6428 9.24873 15.8049 9.24873C11.9764 9.24873 8.875 12.3539 8.875 16.1869ZM21.3947 8.9921C21.3947 9.88657 22.1157 10.6099 23.0106 10.6099C23.9025 10.6099 24.625 9.88657 24.625 8.9921C24.625 8.77974 24.5832 8.56946 24.5021 8.37326C24.4209 8.17707 24.3019 7.9988 24.1519 7.84864C24.0019 7.69848 23.8239 7.57936 23.6279 7.49809C23.432 7.41683 23.2219 7.375 23.0098 7.375C22.7977 7.375 22.5877 7.41683 22.3917 7.49809C22.1958 7.57936 22.0177 7.69848 21.8677 7.84864C21.7177 7.9988 21.5988 8.17707 21.5176 8.37326C21.4364 8.56946 21.3947 8.77974 21.3947 8.9921Z'
      fill={props.fill || 'white'}
    />
    <path
      d='M15.8125 20.125C17.9871 20.125 19.75 18.3621 19.75 16.1875C19.75 14.0129 17.9871 12.25 15.8125 12.25C13.6379 12.25 11.875 14.0129 11.875 16.1875C11.875 18.3621 13.6379 20.125 15.8125 20.125Z'
      fill={props.fill || 'white'}
    />
  </svg>
)

export default InstagramFilled
